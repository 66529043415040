import '@davidosborn/object-extensions'
import React from 'react'
import ReactDOM from 'react-dom/client'

import Router, {Route} from 'components/hostname-router'

import './index.sass'

const DavidSubdomain = React.lazy(() => import('domains/david'))
const RootDomain = React.lazy(() => import('domains/root'))

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<Router>
		<Route element={RootDomain}/>
		<Route subdomain="david" element={DavidSubdomain}/>
	</Router>
)
