import React from 'react'

import Route from './route'

export {
	Route,
	HostnameRouter as Router
}

export default function HostnameRouter(props) {
	const routes = React.Children.toArray(props.children)
		.filter(x => x.type === Route)

	const hostname = window.location.hostname
	const subdomain = hostname.split('.').slice(0, -2).join('.')

	const route = routes.find(x => x.props.hostname === window.location.hostname)
		?? routes.find(x => x.props.subdomain === subdomain)
		?? routes.find(x => !x.props.hostname && !x.props.subdomain)

	if (!route) {
		return '404 Not Found'
	}

	const Element = route.props.element

	return <Element/>
}
